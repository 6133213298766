@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap");
@import url('https://fonts.cdnfonts.com/css/book-antiqua');
@import 'pure-react-carousel/dist/react-carousel.es.css';


@layer base {
  html {
    font-family: 'Inter';
    background-size: "100% 80%";
    background-repeat: repeat;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
}

.background-class.loading {
  background-image: none;
  /* any other styles you want to apply when loading */
}
.font-fallback {
  font-family: Arial, sans-serif;
}

